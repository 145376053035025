import ApplicationService from '@/services/application.service';
import { plainToClass } from 'class-transformer';
import { Application } from '../Entities/Application/Application';
import { Applicant } from '../Entities/Personas/Applicant';
import { Recruiter } from '../Entities/Personas/Recruiter';

class RecruiterApplicant extends Recruiter {
  profileIncomplete!: boolean;
  applicationPartiallyComplete!: boolean;
  recruiterSignatureRequired!: boolean;
  readyForBoardSubmission!: boolean;

  static newFromApplicant(
    applicant: Applicant,
    applications: Application[]
  ): RecruiterApplicant {
    let recruiterSignatureRequired;
    let readyForBoardSubmission;
    let applicationPartiallyComplete;

    //TODO: verify that this works
    const profileIncomplete = applicant.progress && applicant.progress < 100;

    if (ApplicationService.doesApplicationsExist(applications)) {
      const latestApplication =
        ApplicationService.getLatestApplicationByDate(applications);
      recruiterSignatureRequired =
        !latestApplication?.commanderRemarksComments ||
        !latestApplication?.commanderRemarksName;
      applicationPartiallyComplete = latestApplication
        ? !latestApplication.isComplete
        : true;
      readyForBoardSubmission =
        latestApplication &&
        applicant.progress === 100 &&
        latestApplication.isComplete;
    } else {
      recruiterSignatureRequired = false;
      applicationPartiallyComplete = false;
      readyForBoardSubmission = false;
    }
    const FlatApplicantAndApplication = {
      ...applicant,
      profileIncomplete: profileIncomplete,
      recruiterSignatureRequired: recruiterSignatureRequired,
      readyForBoardSubmission: readyForBoardSubmission,
      applicationPartiallyComplete: applicationPartiallyComplete
    };
    return plainToClass(RecruiterApplicant, FlatApplicantAndApplication);
  }
}
export { RecruiterApplicant };
