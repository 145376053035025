
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import IconComponent from '@/components/IconComponent.vue';
import { IQuickFilterFilter } from '@/models/Filters/QuickFilterFilter';

@Component<QuickFilterComponent>({ components: { IconComponent } })
export default class QuickFilterComponent extends Vue {
  @Prop({
    default: () => []
  })
  readonly filters!: IQuickFilterFilter<unknown>[];

  private readonly INVALID_FILTER_ID = -1;

  @Prop()
  activeFilterId!: number;

  filterIsActive(id: number): boolean {
    return id === this.activeFilterId;
  }

  @Emit('clickFilter')
  handleFilterClick(id: number): number {
    return id;
  }
}
