import { Type, plainToClass } from 'class-transformer';
import { TableFilterTypes } from './LegacyTable';

interface IQuickFilterFilter {
  filter: string | null;
  filterType?: TableFilterTypes | null;
  column?: string;
  count: number;
  countStyle?: string | null;
  showIcon?: boolean;
}

export default class QuickFilterFilter implements IQuickFilterFilter {
  public filter = null;
  public filterType = TableFilterTypes.STRING_EXACT;
  public column = '';
  @Type(() => Number)
  public count = 0;
  public countStyle = null;
  @Type(() => Boolean)
  showIcon = false;

  constructor(quickFilterFilter: IQuickFilterFilter) {
    if (quickFilterFilter) {
      Object.assign(this, plainToClass(QuickFilterFilter, quickFilterFilter));
    }
  }
}
